import React from 'react'

import SEO from '../components/seo'

import { Link } from 'gatsby'
import Layout from '../components/layout'

const IndexPage = () => {
  return (

      <Layout>
        <SEO title="404 PAGE NOT FOUND" description='PAGE NOT FOUND' />

        <img
        src={`../../golang.png`} />

      <h1>PAGE NOT FOUND , My blog is amazing. Why don't you read it once before you leave my site?</h1>
      <Link to='/'>Go Homepage</Link>
      </Layout>
  )
}

export default IndexPage
